import React, { useState, useEffect, useMemo } from "react"
import getFilterValueDisplay from "./view-helpers/getFilterValueDisplay"
import styles from '../assets/css/DocumentClassification.Module.css'
import {AiOutlineArrowRight} from 'react-icons/ai'
import PetriDish from '../assets/icons/9-PetriDish.svg'
import Operation from '../assets/icons/18-Operations.svg'
import Safety from '../assets/icons/21-Safety.svg'
import Brain from '../assets/icons/29-Brain-NEW.svg'
import Cookies from 'js-cookie'
import { Link } from "react-router-dom"

function uniqueByKey(array, value) {
    return array.filter((e, i) => array.findIndex(a => a[value] === e[value]) === i);
 }

const DocumentClassifications = ({ className,
    label,
    onMoreClick,
    onRemove,
    onSelect,
    options,
    showMore,
    showSearch,
    onSearch,
    searchPlaceholder,
    docClass,optionClickRemove,optionClickAdd, optionsMap, t, Cookie_expiry_time }) => {
    
   
    let iconImage = '';
    // const diffOptions = () => {
    //    if (docClass === undefined && options != undefined)
    //    return options;

    //     if (options === undefined & docClass
    //         != undefined)
    //         return docClass
    //             ;
    //     if (docClass
    //         === undefined || (docClass && docClass.length === 0))
    //         return []
    //     if (options.length === docClass
    //         .length)
    //         return options;

    //     var newOptions = []
    //     options.forEach(o => {
    //         docClass
    //             .forEach(d => {
    //                 if (o.value == d.value)
    //                     newOptions[newOptions.length] = o;
    //                 else
    //                     newOptions[newOptions.length] = d;
    //             })
    //     })
      
    //     newOptions = uniqueByKey(newOptions,"value")
    //     newOptions.sort((a,b) =>(a.value>b.value)?1:-1)

    //     return newOptions;
    // }
    var newOptions = options//diffOptions()
    
    return (
        <div className="container ReCP-002">
            <div className="hero-nav row slick-initialized slick-slider">
                <div className="slick-list draggable">
                    <div className="slick-track" style={{ opacity: 1, width: '100%', transform: 'translate(0px, 0px)',flexWrap: 'wrap' }}>
                        {newOptions != undefined && newOptions.length < 1 && <div>No matching options</div>}
                        {newOptions != undefined && newOptions.map((option, key) => {
                            if(getFilterValueDisplay(option.value) === 'Control Ranges'){ 
                                iconImage = PetriDish;
                            }
                            if(getFilterValueDisplay(option.value) === 'Instructions for Use'){ 
                                iconImage = Operation;
                            }
                            if(getFilterValueDisplay(option.value) === 'Safety Data Sheets'){ 
                                iconImage = Safety;
                            }
                            if(getFilterValueDisplay(option.value) === 'Support documentation'){ 
                                iconImage = Brain;
                            }
                            
                            const checked = option.selected;
                            const current = checked ? 'slick-current' : '';
                            let altDispValue = optionsMap[option.value]!==undefined ? optionsMap[option.value] : t(option.value)
                            if(altDispValue===undefined||altDispValue.length===0)
                             altDispValue=t('Other');                
            
                            return (
                                <div key={`docClass-${key}`} className={`col-md-6 col-sm-12 col-lg-3`} data-slick-index="0" aria-hidden="true" tabIndex="-1">
                                    <div className={styles.slideSlick} >
                                        <label
                                            key={`${getFilterValueDisplay(option.value)}`}
                                            htmlFor={`example_facet_${label}${getFilterValueDisplay(
                                                option.value
                                            )}`}
                                            className="sui-multi-checkbox-facet__option-label"
                                        >
                                            <img style={{marginRight: '14px'}} src={iconImage}></img>
                                            <Link to='/tdhome/search-result' style={{display: "inherit", color: '#212529'}} 
                                            
                                            
                                            >

                                            <input
                                                id={`example_facet_${label}${getFilterValueDisplay(
                                                    option.value
                                                )}`}
                                                type="checkbox"
                                                className="hidden"
                                                checked={checked}
                                                onChange={() => {
                                                    checked ? onRemove(option.value) : onSelect(option.value)
                                                    Cookies.set("search-term", option.value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                                                }
                                                }                                                
                                                /*onChange={() =>{
                                                    checked ? onRemove(option.value) : onSelect(option.value)
                                                    checked ? optionClickRemove(option): optionClickAdd(option)
                                                }} */
                                            />
                                            
                                            <h3 className="td-wrap" onClick={() => { onSelect(option.value)
                                            
                                            }}>{altDispValue}</h3>
                                            <AiOutlineArrowRight style={{color:"#cf2f44", fontSize: '28px', marginTop: '10px'}}></AiOutlineArrowRight>
                                            </Link>
                                            
                                        </label>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentClassifications
