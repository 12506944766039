import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
import styles from '../assets/css/Modal.module.css';
import OrthoSelectFacet from './OrthoSelectFacet';
import Cookies from 'js-cookie'
import { getConfig } from "../config/config-helper";
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import ClearFilters from "./ClearFilters";


function ModalDropdown({ setShow, show, userInfo, countrySelect, setCountrySelect, languageSelect, setLanguageSelect, country, language, setFilterData, removeFilt, setFilterMulti, clearFilter, setResultSelect, setCountrySelectedValue, setLanguageSelectedValue }) {
    const handleShow = () => true;
    const [countries, setCountries] = useState([])
    const [load, setLoad] = useState(false);
    const [docClass, setDocClass] = useState([])
    const [languages, setLanguages] = useState([])
    const [resultsSelected, setResultsSelected] = useState([])
    // const [userInfo, setUserInfo] = useState([])
    const { Cookie_expiry_time,  /*,TRACKING_ID, precision*/ } = getConfig();
    const { t } = useTranslation();
    const setLoading = (value) => {
        setLoad(value)
    }
    useEffect(()=>{
        countrySelect = countrySelect?.trim() ? countrySelect : (isEmployee(userInfo) ? "ALL" : "") 
        languageSelect = languageSelect?.trim() ? languageSelect : (isEmployee(userInfo) ? "ALL" : "") 
    },[])
    const handleClose = () => {
        if (Cookies.get("Techdocs-Temp-Language") != null && Cookies.get("Techdocs-Language") != null && Cookies.get("Techdocs-Temp-Language") !== Cookies.get("Techdocs-Language")) {
            Cookies.set("Techdocs-Temp-Language", Cookies.get("Techdocs-Language"), { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
            setLanguageSelect(Cookies.get("Techdocs-Language"))
        }
        if (Cookies.get("Techdocs-Temp-Country") != null && Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Temp-Country") !== Cookies.get("Techdocs-Country")) {
            Cookies.set("Techdocs-Temp-Country", Cookies.get("Techdocs-Country"), { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
            setCountrySelect(Cookies.get("Techdocs-Country"))
        }

        setShow(false);
    }


    function getAudienceType(userInfo) {
        var audience = null;
        if (Cookies.get("at") === undefined)
            audience = ["Public"];
        else {
            var employee = isEmployee(userInfo)
            var distributor = isDistributorFn(userInfo)
            audience = (employee || distributor) ? ["Public", "Internal", "Authenticated"] : ["Public", "Authenticated"]

        }
        return audience;
    }

    function isEmployee(userInfo) {
        var employee = false;
        if (userInfo && userInfo["custom:UserType"] === 'E')
            employee = true;
        return employee;
    }

    function isDistributorFn(userInfo) {
        var distributor = false;
        //if (userInfo && userInfo["custom:UserType"] === 'D')
        if (userInfo && (userInfo["custom:UserType"] === 'D' || userInfo["custom:FeatureList"] && userInfo["custom:FeatureList"].indexOf("isDistributor") !== -1))
            distributor = true;
        return distributor;

    }
    function onChangeCountry(value) {
        Cookies.set("Techdocs-Temp-Country", value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        const currentCountrytoBeSet = { ["count"]: 0, ["value"]: value, ["selected"]: true }
        let prevCountry = countrySelect;
        const prevCountryToBeReSet = { ["count"]: 0, ["value"]: prevCountry, ["selected"]: false }
        setCountrySelect(value)
    }

    function onChangeLanguage(value) {
        Cookies.set("Techdocs-Temp-Language", value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        const currentLanguagetoBeSet = { ["count"]: 0, ["value"]: value, ["selected"]: true }
        let prevLang = languageSelect;
        const prevLanguageToBeReSet = { ["count"]: 0, ["value"]: prevLang, ["selected"]: false }
        setLanguageSelect(value)
    }
    function onApply() {
        let docClasses = [];
        if (countrySelect != null)
            Cookies.set("Techdocs-Country", countrySelect, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        if (languageSelect != null)
            Cookies.set("Techdocs-Language", languageSelect, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        if ((Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null)) {
            setDocClass([...docClasses]);
            if (Cookies.get("Techdocs-Language") == 'ALL' && Cookies.get("Techdocs-Country") == 'ALL') {
                removeFilt("language");
                removeFilt("regioncountrylist");
            } else if (Cookies.get("Techdocs-Country") == 'ALL' && Cookies.get("Techdocs-Language") !== 'ALL') {
                removeFilt("regioncountrylist");
                setFilterData("language", [languageSelect, "Multiple Languages"], "any");
            } else if (Cookies.get("Techdocs-Language") == 'ALL' && Cookies.get("Techdocs-Country") !== 'ALL') {
                removeFilt("language");
                setFilterData("regioncountrylist", Cookies.get("Techdocs-Country"), "any");
            } else {
                setFilterData("language", [languageSelect, "Multiple Languages"], "any");
                setFilterData("regioncountrylist", Cookies.get("Techdocs-Country"), "any");
            }
            setFilterData("audience", [...getAudienceType(userInfo)], "any");
            // clearFilter(["regioncountrylist", "language", "audience", "usage_type", "expirationdate"]);
            setResultSelect([]); //reset any selected for downloads
        }
        handleClose();
    }
    return (
        <>
            <Modal className={styles.modal} style={{ padding: "20px !important", zIndex: "10000" }} show={show} onHide={handleClose}>
                <Modal.Header className={styles.modalHeader} closeButton>
                    <Modal.Title className={styles.modalTitle}>{t('Select_country_lang')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.dropdownSelectors}>
                    <div className="languageCountry-form">
                        {country && country.length > 0 &&
                            <OrthoSelectFacet isEmployee={isEmployee(userInfo)} className="orthoform" label={t('Country')} onChange={onChangeCountry} options={country} cookieName="Techdocs-Temp-Country" cmpnt="regioncountrylist" load={load} setLoading={setLoading} t={t} />
                        }
                    </div>
                    {/* <select class="form-select" aria-label="Default select example">
                            {language && language.length > 0 && language.map(co => {
                                return (
                                    <option selected>{co}</option>
                                )
                            })}

                        </select> */}
                    <div className="languageCountry-form">
                        {language && language.length > 0 &&
                            <OrthoSelectFacet isEmployee={isEmployee(userInfo)} className="orthoform" label={t('Language')} onChange={onChangeLanguage} options={language} cookieName="Techdocs-Temp-Language" cmpnt="language" load={load} setLoading={setLoading} t={t} />
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button className={styles.buttonSecondary} variant="secondary" onClick={() => {
                        handleClose()
                    }}>
                        {t('Cancel')}
                    </Button>
                    {((countrySelect && languageSelect) ||
                        (Cookies.get("Techdocs-Temp-Country") != null && Cookies.get("Techdocs-Temp-Language") != null)) &&
                        <Button className={styles.buttonPrimary} variant="primary" onClick={() => onApply()}>{t('Apply')}</Button>}
                </Modal.Footer>
            </Modal>
        </>
    );
}





export default ModalDropdown;