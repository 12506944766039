
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { getConfig } from '../config/config-helper';
// import styles from '../assets/css/DocumentClassification.Module.css'
// import Loader from "../components/Loader"

var ElasticAppSearch = require("@elastic/app-search-javascript");

const { public_url, auth_url, search_key, /*, TRACKING_ID */ } = getConfig();

const GetCompoundDoc = ({ compoundDoc, engineName, downloadDoc, setLoading }) => {
    const [compoundDocs, setCompoundDocs] = useState([]);
    const fileTypeobject = {
        property: ["pdf", "docx", "doc", "pptx", "xlsx", "xls", "msg", "ppt","zip"]
    }
    useEffect(() => {
        var key = Cookies.get("at") != null ? Cookies.get("at") : search_key;
        var url = Cookies.get("at") != null ? auth_url : public_url;
        // setLoading(true);
        var client = ElasticAppSearch.createClient({ searchKey: key, endpointBase: url, engineName: engineName });
        client.search("", {
            page: { size: 100 },
            filters: { all: [{ parenttype: "CompoundDocument" }, { parentid: compoundDoc.parentid.raw }] },
            result_fields: { name: { raw: {} }, title: { raw: {} }, filename: { raw: {} }, datasize: { raw: {} }, filetype: { raw: {} }, parentid: { raw: {} }, parenttype: { raw: {} }, id: { raw: {} } },
        }).then(resultList => {
            const newData = [];
            resultList.results.forEach((element) => {
                if (!element.data.name.raw.includes(compoundDoc.recordid.raw)) {
                    if (fileTypeobject.property.includes(element.data.filetype.raw.toLowerCase())) {
                        newData.push(element);
                    }

                }
            });
            const newArry = createGroups(newData, 2);
            setCompoundDocs(newArry);
            // setLoading(false);
        })
            .catch(error => {
                // setLoading(false);
            })
    }, [])
    function createGroups(arr, numGroups) {
        const perGroup = Math.ceil(arr.length / numGroups);
        return new Array(numGroups)
            .fill('')
            .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
    }


    return (

        <div style={{ marginBottom: '0px', marginLeft: '35px', paddingBottom: '4px' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr' }}>
                <div>
                    {compoundDocs[0]?.map((item, index) => (
                        <>
                            <p key={`com-${index}`}>
                                <a className='compoundDoc' style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak:'break-all' }} href='#' onClick={(evt) => {setLoading(false); downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.filename.raw, evt, 'E', Cookies.get('at'), setLoading, false); }}>{item.data.filename.raw}</a>
                            </p>

                        </>
                    ))}
                </div>
                <div>
                    {compoundDocs[1]?.map((item, index) => (
                        <>
                            <p key={`comp-${index}`}>
                                <a style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak:'break-all' }} href='#' onClick={(evt) => {setLoading(false); downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.filename.raw, evt, 'E', Cookies.get('at'), setLoading, false); }}>{item.data.filename.raw}</a>
                            </p>

                        </>
                    ))}
                </div>
            </div>

        </div>
    );
}


export default GetCompoundDoc;